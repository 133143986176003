<template>
  <b-card>
    <b-overlay
      :show="loading"
      variant="light"
      opacity="0.30"
      blur="10px"
      rounded="sm"
      class="mt-2 mb-2"
    >
      <b-row class="justify-content-md-end">
        <!-- submit and reset -->
        <b-col cols="3">
          <import-excel :on-success="loadDataInTable" />
        </b-col>
        <b-col>
          <b-button
            :href="defaultCityExcelTemplate"
            class="mr-1 float-right custom-button-color"
            type="submit"
          >
            <feather-icon
              icon="DownloadIcon"
              class="mr-50"
            />
            Download Excel Template
          </b-button>
        </b-col>
      </b-row>

      <ValidationObserver
        ref="createExcelBulkUploadForm"
        v-slot="{ handleSubmit }"
        slim
      >
        <b-form
          v-if="tableData.length > 0"
          ref="form"
          class="repeater-form"
          autocomplete="off"
          @submit.prevent="handleSubmit(onClickSubmit)"
        >
          <b-row class="mt-4 mb-2">
            <!-- Country Dropdown -->
            <b-col
              cols="6"
              md="3"
              class="ml-1"
            >
              <b-form-group
                class="required"
                label="Country"
                label-for="h-country"
              >
                <ValidationProvider
                  #default="{ errors }"
                  name="name"
                  rules="required"
                >
                  <v-select
                    v-model="country_id"
                    :options="countryOptions"
                    :reduce="option => option.id"
                    label="name"
                    placeholder="Select Country"
                  >
                    <template #search="{attributes, events}">
                      <input
                        class="vs__search"
                        style="pointer-events:none"
                        :required="!country_id"
                        v-bind="attributes"
                        v-on="events"
                      >
                    </template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-table
            responsive
            :fields="fields"
            :items="tableData"
            :small="true"
            class="pt-5 pb-5 excelUploadtable"
            show-empty
          >
            <template #empty>
              <div
                class="text-center p-1"
              >
                No records found to display
              </div>
            </template>
            <template
              v-for="field in fields"
              v-slot:[`cell(${field.key})`]="{ item }"
            >
              <!--eslint-disable-->
              <b-form-input
                v-if="field.key == 'city_name'"
                :class="{ 'border-error': item._error }" 
                v-model="item[field.key]"
                type="text"
                class="display-4"
                style="width: 200px"
                required
              />
              <b-form-input
                v-if="field.key == 'postal_code'"
                v-model="item[field.key]"
                type="number"
                class="display-4"
                style="width: 200px"
                required
              />
              <ValidationProvider
                v-if="field.key == 'state_id'"
                #default="{ errors }"
                name="h-c-state"
                vid="h-c-state"
              >
                <v-select
                  v-if="field.key == 'state_id'"
                  v-model="item[field.key]"
                  :options="states"
                  :reduce="option => option.id"
                  label="name"
                  placeholder="Select State"
                  style="width: 250px"
                  @search="(search, loading) => {
                    loading(true)
                    readDataforState(search).then(() => loading(false))
                  }"
                >
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      style="pointer-events:none"
                      :required="!item[field.key]"
                      v-bind="attributes"
                      v-on="events"
                    >
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
              <ValidationProvider
                v-if="field.key == 'warehouse_type'"
                #default="{ errors }"
                name="h-c-state"
                vid="h-c-state"
              >
                <b-dropdown
                  v-if="field.key == 'warehouse_type'"
                  v-model="item.warehouse_type"
                  :option="warehouseOptions"
                  :reduce="option => option.key"
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  :text="item.warehouse_type ? item.warehouse_type : 'Select Warehouse Type'"
                  variant="outline-secondary"
                >
                  <b-dropdown-item
                    v-if="item.warehouse_type !== 'Agent'"
                    v-model="item.warehouse_type"
                    :reduce="option => option.key"
                    class="custom-control-primary"
                    name="warehouseTypedropdown"
                    value="Agent"
                    @click="item.warehouse_type = 'Agent'; item.default_warehouse_id = '';"
                  >
                    Agent
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="item.warehouse_type !== 'Branch'"
                    v-model="item.warehouse_type"
                    :reduce="option => option.key"
                    class="custom-control-primary"
                    name="warehouseTypedropdown"
                    value="Branch"
                    @click="item.warehouse_type = 'Branch'; item.default_warehouse_id = '';"
                  >
                    Branch
                  </b-dropdown-item>
                </b-dropdown>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
              <ValidationProvider
                v-if="field.key == 'default_warehouse_id'"
                #default="{ errors }"
                name="name"
                rules="required"
              >
                <v-select
                  v-if="field.key == 'default_warehouse_id'"
                  v-model="item.default_warehouse_id"
                  :options="item.warehouse_type === 'Agent' ? agencyOptions : branchOptions"
                  :reduce="option => option.id"
                  label="name"
                  :placeholder="item.warehouse_type === 'Agent' ? 'Select Agent' : 'Select Branch'"
                  style="width: 250px"
                  @search="(search, loading) => {
                    loading(true);
                    if (item.warehouse_type === 'Agent') {
                      readDataforAgency(search).then(() => loading(false));
                    } else if (item.warehouse_type === 'Branch') {
                      readDataforBranch(search).then(() => loading(false));
                    }
                  }"
                >
                  <template #search="{attributes, events}">
                    <input
                      class="vs__search"
                      style="pointer-events:none"
                      :required="!item[field.key]"
                      v-bind="attributes"
                      v-on="events"
                    >
                  </template>
                </v-select>
                <small class="text-danger">{{ errors[0] }}</small>
              </ValidationProvider>
              <!-- Error message display -->
              <small v-if="field.key == 'city_name'" class="text-danger">{{ item._error }}</small>
            </template>
          </b-table>

          <b-row class="justify-content-md-end">
            <!-- submit and reset -->
            <b-col>
              <b-button
                class="mr-1 float-right custom-button-color"
                type="submit"
                :disabled="loading"
              >
                Submit
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </ValidationObserver>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BButton, BRow, BCol, BForm, BOverlay, BDropdown, BDropdownItem, BFormInput,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import SuccessMessage from '@core/mixins/SuccessMessage'
import ErrorMessage from '@core/mixins/ErrorMessage'
import ImportExcel from '@core/components/excel/ImportExcel.vue'
import { RepositoryFactory } from '@/repository/RepositoryFactory'
import { tenant, bucketUrl, defaultCityExcelTemplate } from '@/constants/config'
import { v4 as uuidv4 } from 'uuid'

const ResourceRepository = RepositoryFactory.get('resource')

export default {
  components: {
    BCard,
    ImportExcel,
    BTable,
    BButton,
    BRow,
    BCol,
    BForm,
    BOverlay,
    vSelect,
    BDropdown,
    BDropdownItem,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  mixins: [SuccessMessage, ErrorMessage, heightTransition],
  data() {
    return {
      tenant,
      bucketUrl,
      defaultCityExcelTemplate,
      items: [],
      nextTodoId: 2,
      tableData: [],
      header: [],
      sheetName: '',
      states: [],
      countryOptions: [],
      businesses: [],
      loading: false,
      warehouseOptions: [
        {
          key: 'Branch',
          name: 'Branch',
        },
        {
          key: 'Agent',
          name: 'Agent',
        },
      ],
      branchOptions: [],
      agencyOptions: [],
      fields: [
        { key: 'city_name', label: 'City Name', editable: true },
        { key: 'postal_code', label: 'Postal Code', editable: true },
        { key: 'state_id', label: 'State', editable: true },
        { key: 'warehouse_type', label: 'Warehouse Type', editable: true },
        { key: 'default_warehouse_id', label: 'Default Warehouse', editable: true },
      ],
      chunkSize: 500,
    }
  },
  async mounted() {
    this.initTrHeight()
    this.readDataforAgency()
    this.readDataforBranch()
    this.readDataforCountry()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    async loadDataInTable({ results, header, meta }) {
      this.loading = true
      this.header = header
      const loadedStates = new Set()
      const loadedAgencies = new Set()
      const loadedBranches = new Set()

      await Promise.all([
        ...results.map(async n => {
          if (!loadedStates.has(n.state)) {
            loadedStates.add(n.state)
            await this.readDataforState(n.state)
          }
        }),
        ...results.map(async a => {
          if (!loadedBranches.has(a.default_warehouse)) {
            loadedBranches.add(a.default_warehouse)
            await this.readDataforBranch(a.default_warehouse)
          }
        }),
        ...results.map(async a => {
          if (!loadedAgencies.has(a.default_warehouse)) {
            loadedAgencies.add(a.default_warehouse)
            await this.readDataforAgency(a.default_warehouse)
          }
        }),
      ])
      this.tableData = results.map(city => ({
        ...city,
        uuid: uuidv4(), // Generate UUID for each city
      }))
      this.tableData = this.tableData.map(n => {
        const obj = { ...n }
        // eslint-disable-next-line no-confusing-arrow
        obj.state_id = this.states.find(m => m.name.toLowerCase() === (n.state ? n.state.toLowerCase() : null)) ? this.states.find(m => m.name.toLowerCase() === (n.state ? n.state.toLowerCase() : null)).id : null
        return obj
      })
      this.tableData = this.tableData.map(a => {
        const obj = { ...a } // Create a copy of the original object
        const agencyMatch = this.agencyOptions.find(b => b.name.toLowerCase() === (a.default_warehouse ? a.default_warehouse.toLowerCase() : null))
        const branchMatch = this.branchOptions.find(b => b.name.toLowerCase() === (a.default_warehouse ? a.default_warehouse.toLowerCase() : null))

        if (agencyMatch) {
          obj.default_warehouse_id = agencyMatch.id
        } else if (branchMatch) {
          obj.default_warehouse_id = branchMatch.id
        } else {
          obj.default_warehouse_id = null
        }

        return obj
      })
      this.sheetName = meta.sheetName
      this.loading = false
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    async readDataforState() {
      try {
        const { data } = (await ResourceRepository.getStateListDropdown()).data
        data.forEach(n => {
          if (!this.states.find(m => m.id === n.id)) {
            this.states.push(n)
          }
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforCountry() {
      try {
        const { data } = (await ResourceRepository.getCountryList()).data
        this.countryOptions = data
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    async readDataforBranch() {
      try {
        const { data } = (await ResourceRepository.getBranchListDropdown()).data
        data.forEach(a => {
          if (!this.branchOptions.find(b => b.id === a.id)) {
            this.branchOptions.push(a)
          }
        })
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
    async readDataforAgency() {
      try {
        this.agencyOptions = localStorage.getItem('agent_list') ? JSON.parse(localStorage.getItem('agent_list')) : (await ResourceRepository.getAgencyListDropdown()).data.data
        if (!localStorage.getItem('agent_list')) localStorage.setItem('agent_list', JSON.stringify(this.agencyOptions))
      } catch (e) {
        this.convertAndNotifyError(e)
      }
    },
    processTableData() {
      const payloadTemplate = {
        cities: [],
        country_id: this.country_id,
        batchID: [],
      }

      // Function to chunk an array into smaller arrays
      function chunkArray(array, size) {
        return Array.from({ length: Math.ceil(array.length / size) }, (_, index) => array.slice(index * size, (index + 1) * size))
      }

      // Chunk tableData into smaller arrays
      const chunkedCities = chunkArray(this.tableData, this.chunkSize)

      // Process each chunk and return as array of payloads
      return chunkedCities.map((chunk, index) => {
        const batchID = index + 1
        const payload = { ...payloadTemplate, cities: [], batchID: [batchID] }

        // Iterate through each city in the chunk
        chunk.forEach(city => {
          const obj = {
            ...city,
            country_id: this.country_id,
          }

          payload.cities.push(obj)
        })

        return payload
      })
    },
    async onClickSubmit() {
      this.loading = true
      try {
        const chunkedPayloads = this.processTableData()

        // Create a function to handle each payload
        const processPayload = async payload => {
          try {
            await ResourceRepository.createExcelCity(payload)
            this.clearSuccessRows(payload.cities)
            this.showSuccessMessage('City created successfully')
          } catch (error) {
            if (error.response && error.response.data && error.response.data.errors) {
              const cities = error.response.data.errors
              // Iterate over each city and show its error message separately
              Object.keys(cities).forEach(cityKey => {
                const cityErrors = cities[cityKey]
                cityErrors.forEach(cityError => {
                  const errorMessage = `${cityError}`
                  this.showErrorMessage(errorMessage)
                })
              })
            } else if (error.response && error.response.data && error.response.data.data) {
              const errorMessage = error.response.data.message
              this.showErrorMessage(errorMessage)

              const errorUuids = error.response.data.data.uuid
              const successCities = payload.cities.filter(city => !errorUuids.includes(city.uuid))

              this.clearSuccessRows(successCities)
              errorUuids.forEach(cityUUID => {
                const cityErrors = [errorMessage]
                const cityIndex = this.tableData.findIndex(city => city.uuid === cityUUID)
                if (cityIndex !== -1) {
                // Set error message under the city name
                  this.$set(this.tableData[cityIndex], '_error', cityErrors.join(', '))
                }
              })
            } else {
              this.showErrorMessage(error.message || 'An error occurred in city bulk creating.')
            }
          }
        }

        // Create a sequentially processing loop using forEach and async/await
        const processAllPayloads = async () => {
          for (let i = 0; i < chunkedPayloads.length; i += 1) {
            // eslint-disable-next-line no-await-in-loop
            await processPayload(chunkedPayloads[i])
          }
        }

        await processAllPayloads()
      } catch (error) {
        this.showErrorMessage(error.message || 'An unexpected error occurred.')
      }

      this.loading = false
    },
    clearSuccessRows(successCities) {
      if (successCities) {
        this.tableData = this.tableData.filter(city => !successCities.find(c => c.uuid === city.uuid))
      } else {
        this.tableData = []
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
.textSmall {
    max-width: 1000px;
}
.excelUploadtable{
  padding-bottom: 250px !important;
}
.custom-button-color {
  background-color: #4186f5 !important;
  border-color: #4186f5 !important;
}
.border-error {
  border-color: red !important;
}
</style>
